div.waitingConnection {
  background: orange;
  animation: grow 0.75s infinite;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

div.connected {
  background: limegreen;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

@keyframes grow {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 0.75;
  }
}
